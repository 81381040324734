<template>
    <div class="add_visits_info">
        <!-- <p class="tips">准确的填写问诊人信息，有助于医生问诊</p> -->
        <van-cell title="与您的关系" :value="relation" />
        <van-cell :value="name" title="姓名" disabled :maxlength="10" />
        <!-- <van-field v-model="idNo" label="身份证号" placeholder="请输入您的身份证号码" @blur="onBlurChange" />/ -->
        <van-cell title="性别" title-style="width:2rem;flex:none">
            <van-radio-group v-model="sex" checked-color="#FFC025" disabled>
                <van-radio name="1">男</van-radio>
                <van-radio name="2">女</van-radio>
            </van-radio-group>
        </van-cell>
        <van-cell title="出生日期" :value="birthday" />
        <van-cell :value="weight" title="体重" disabled :maxlength="10" />
        <van-cell title="过敏史" :value="allergy" />
        <van-cell title="过往病史" :value="historyIll" />
        <van-cell title="肝功能" :value="liver" />
        <van-cell title="肾功能" :value="renal" />
    </div>
</template>
<script>
import { Toast } from 'vant';
import { getDate, getIdCardInfo, IsPhoneAvailable, checkIDCard } from '@/utils/util';
export default {
    data() {
        return {
            icon: require('../assets/img/require.png'),
            name: '',
            idNo: '',
            weight: '',
            sex: '',
            textValue: '',
            birthday: '请选择',
            relationId: null,
            currentDate: new Date(),
            showBirth: false,
            minDate: new Date(1900, 0, 1),
            maxDate: new Date(),
            showRelation: false,
            relation: '请选择',
            actions: [{
                name: '本人',
                id: 1
            },
            {
                name: '父母',
                id: 2
            },
            {
                name: '子女',
                id: 3
            },
            {
                name: '其他',
                id: 4
            }
            ],
            allergy: '无', //过敏史
            historyIll: '无', //过往病史
            liver: '正常', //肝功能
            renal: '正常', //肾功能
            showIllPopup: false,
            placeholder: '',
            title: '',
            query: {

            },
            isIdNo: true
        }
    },
    created() {
        if (this.$route.query.item) {
            // this.onDetail(this.$route.query.id);
            this.query = JSON.parse(this.$route.query.item);
            this.relationId = this.query.relation;
            this.name = this.query.interrogationName;
            this.relation = this.getRelation(this.query.relation) || '请选择';
            this.idNo = this.query.idNo;
            this.sex = this.query.sex + '';
            this.birthday = this.query.birthday;
            this.weight = this.query.weight;
            this.allergy = this.query.allergicHistory || '无';
            this.historyIll = this.query.pastMedicalHistory || '无';
            this.liver = this.query.liverFunction || '正常';
            this.renal = this.query.renalFunction || '正常';
        }
    },
    methods: {
        onBlurChange() {
            // console.log('sfsd')
            if (!checkIDCard(this.idNo)) {
                Toast('请输入正确格式的身份证号');
                this.isIdNo = false;
                return;
            } else {
                this.isIdNo = true;
            }
            // console.log(getIdCardInfo(this.idNo.length, this.idNo));
            let info = getIdCardInfo(this.idNo.length, this.idNo);
            this.sex = info.sex + '';
            this.birthday = info.birthday;
        },
        getRelation(relation) {
            return {
                1: '本人',
                2: '父母',
                3: '子女',
                4: '其他'
            }[relation]
        },
        onSelect(e) {
            // console.log(e);
            this.relation = e.name;
            this.relationId = e.id;
            // this.query.relation = e.id;
            this.showRelation = false;
        },
        // 过敏史
        showAllergy() {
            this.showIllPopup = true;
            this.title = '过敏史';
            this.placeholder = '请输入您的过敏史';
            if (this.query.id) {
                this.textValue = this.query.allergicHistory || '';
            } else {
                this.textValue = this.allergy == '无' ? '' : this.allergy;
            }
        },
        // 过往病史
        showHistoryIll() {
            this.showIllPopup = true;
            this.title = '过往病史';
            this.placeholder = '请输入您的过往病史';
            if (this.query.id) {
                this.textValue = this.query.pastMedicalHistory || '';
            } else {
                this.textValue = this.historyIll == '无' ? '' : this.historyIll;
            }
        },
        // 肝功能
        showLiver() {
            this.showIllPopup = true;
            this.title = '肝功能';
            this.placeholder = '请输入您的肝功能异常描述';
            if (this.query.id) {
                this.textValue = this.query.liverFunction || '';
            } else {
                this.textValue = this.liver == '正常' ? '' : this.liver;
            }
        },
        // 肾功能
        showRenal() {
            this.showIllPopup = true;
            this.title = '肾功能';
            this.placeholder = '请输入您的肾功能异常描述';
            if (this.query.id) {
                this.textValue = this.query.renalFunction || '';
            } else {
                this.textValue = this.renal == '正常' ? '' : this.renal;
            }
        },
        // 出生日期
        onConfirmDate(value) {
            // console.log(value);
            this.birthday = getDate('-', value.getTime(), false);
            this.showBirth = false;
        },
        onSure() {
            this.showIllPopup = false;
            if (this.title === '过敏史') {
                this.allergy = this.textValue === '' ? '无' : this.textValue;
            } else if (this.title == '过往病史') {
                this.historyIll = this.textValue === '' ? '无' : this.textValue;
            } else if (this.title === '肝功能') {

                this.liver = this.textValue === '' ? '正常' : this.textValue;
            } else {
                this.renal = this.textValue === '' ? '正常' : this.textValue;
            }
        },
        onCancel() {
            this.showIllPopup = false;
            if (this.query.id) {
                this.allergy = this.query.allergicHistory || '无';
                this.historyIll = this.query.pastMedicalHistory || '无';
                this.liver = this.query.liverFunction || '正常';
                this.renal = this.query.renalFunction || '正常';
            } else {
                if (this.title === '过敏史') {
                    this.allergy = this.textValue === '' ? '无' : this.allergy;
                } else if (this.title == '过往病史') {
                    this.historyIll = this.textValue === '' ? '无' : this.historyIll;
                } else if (this.title === '肝功能') {

                    this.liver = this.textValue === '' ? '正常' : this.liver;
                } else {
                    this.renal = this.textValue === '' ? '正常' : this.renal;
                }
            }
        },
        // 添加
        submit() {
            let userData = JSON.parse(localStorage.getItem('userData')) || ''
            if (!this.relationId) {
                Toast('请选择问诊人与您的关系');
                return;
            }
            if (this.idNo) {
                if (!this.isIdNo) {
                    Toast('请输入正确格式的身份证号');
                    return;
                }
            }
            if (!this.name) {
                Toast('请输入您的姓名');
                return;
            }
            if (!this.sex) {
                Toast('请选择性别');
                return;
            }
            if (!this.birthday) {
                Toast('请选择出生日期');
                return;
            }
            let params = {
                memberId: userData.memberId,  //会员id
                interrogationName: this.name,  //问诊人信息
                idNo: this.idNo, // 身份证
                sex: this.sex, //性别 
                birthday: this.birthday, //出生日期
                relation: this.relationId, //关系
                weight: this.weight, // 体重
                allergicHistory: this.allergy == '无' ? '' : this.allergy, //过敏史
                pastMedicalHistory: this.historyIll == '无' ? '' : this.historyIll, //过往病史
                liverFunction: this.liver == '正常' ? '' : this.liver, //肝功能
                renalFunction: this.renal == '正常' ? '' : this.renal //肾功能
            }
            // console.log(params);
            if (this.query.id) {//编辑
                params.id = this.query.id;
                this.$http('post', '/server/interrogationUserInfo/addOrUpdate', params, 1).then(res => {
                    // console.log(res);
                    if (res.data.code == 200) {
                        Toast('修改成功');
                        this.$router.go(-1);
                    } else {
                        Toast(res.data.msg);
                    }
                })
            } else {
                this.$http('post', '/server/interrogationUserInfo/addOrUpdate', params, 1).then(res => {
                    // console.log(res);
                    if (res.data.code == 200) {
                        Toast('添加成功');
                        this.$router.go(-1);
                    } else {
                        Toast(res.data.msg);
                    }
                })
            }
        }
    }
}
</script>
<style lang="less" scoped>
.add_visits_info {
  padding-top: 0.2rem;
  min-height: calc(100% - 0.2rem);
  background: #f8f8f8;
  /deep/ .van-icon__image {
    margin-right: -0.1rem;
    margin-bottom: 0.08rem;
  }
  /deep/ .van-radio {
    margin-right: 0.6rem;
  }
  .tips {
    font-size: 0.24rem;
    color: #666;
    margin-left: 0.24rem;
    margin-bottom: 0.2rem;
  }
  /deep/ .van-cell__title {
    font-size: 0.28rem;
    color: #333;
    font-weight: 400;
  }
  /deep/ .van-cell__value {
    font-size: 0.26rem;
    color: #999;
  }
  /deep/ .van-radio-group {
    display: flex;
    /deep/ .van-radio {
      margin-right: 0.74rem;
      /deep/ .van-radio__label {
        color: #666 !important;
        font-size: 0.28rem;
      }
    }
  }
  .shadow {
    background: #000;
    opacity: 0.7;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }
  .ill_popup {
    position: absolute;
    width: calc(100% - 2rem);
    // width: calc(100% - 2rem);
    top: 50%;
    left: 0%;
    transform: translateY(-50%);
    background: #fff;
    border-radius: 8px;
    padding-top: 0.2rem;
    text-align: center;
    margin: 0 1rem;
    overflow: hidden;
    .title {
      margin-bottom: 0.2rem;
    }
    .input {
      border: 0;
      //   border-bottom: 1px solid #ebedf0;
      width: calc(100% - 0.4rem);
      margin: 0.2rem 0.2rem;
    }
    .footer_btn {
      display: flex;
      width: 100%;
      div {
        width: 100%;
        // margin-top: 0.1rem;
        padding: 0.2rem 0;
        color: #fff;
        font-size: 0.28rem;
      }
      div:nth-child(1) {
        background: #ffc025;
      }
      div:nth-child(2) {
        color: #ffc025;
        background: #f8f8f8;
      }
    }
  }
  .add_btn {
    margin: 1rem 0.4rem;
    width: calc(100% - 0.8rem);
    height: 0.72rem;
    line-height: 0.72rem;
    background: #fff;
    border: 1px solid #42cec8;
    color: #42cec8;
    border-radius: 4px;
    font-size: 0.32rem;
  }
}
</style>


